import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { getBills } from '../../redux/actions/billActions'
import moment from 'moment/moment'
import Loader from '../../utility/Loader'
import {copyIcon, editIcon, deleteIcon, copyToPI} from '../../utility/svg'
import Tooltip from '@mui/material/Tooltip';
import DeleteHandler from '../../components/BillingSystem/DeleteHandler'
import DuplicateHandler from '../../components/BillingSystem/DuplicateHandler'
import ConvertHandler from '../../components/BillingSystem/ConvertHandler'

const Bills = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {bills, loading} = useSelector(state=>state.bills)
    const [selectedBillId, setSelectedBillId] = useState()
    let financialTermInParams = (new URLSearchParams(window.location.search)).get("financialTerm")
    const [financialTerm, setFinancialTerm] = useState(financialTermInParams ? financialTermInParams : 2526)

    useEffect(() => {
        if (bills.length===0 && financialTerm) {
            dispatch(getBills({financialTerm : financialTerm}))
        }
    }, [])

    useEffect(() => {
      const checkFinancialTermUpdated = financialTermInParams ? financialTermInParams : 2526
      console.log(checkFinancialTermUpdated)
      if (bills.length>0 && bills[bills.length - 1].taxYear.toString() !== checkFinancialTermUpdated.toString()) {
          dispatch(getBills({financialTerm : checkFinancialTermUpdated}))
      }
  }, [financialTerm, financialTermInParams])

  const handleConvert = (id) => {
    setSelectedBillId(id)
    document.getElementById("openConvertHandlerModal").click()
  }

  const handleDuplicate = (id) => {
    setSelectedBillId(id)
    document.getElementById("openDuplicateHandlerModal").click()
  }

  const handleDelete = (id) => {
    setSelectedBillId(id)
    document.getElementById("openDeleteHandlerModal").click()
  }

  const navigateToViewBill = (id) => (
    navigate(`/bill/print/${id}`)
  )

  return loading ? (<Loader/>) : (
    <section className='py-5 bg-primary min-vh-100'>
      <div className="container">
        <div className="row">
          <div className="col-6 flex-center"><Link to={'/'} className='h2 text-white text-center fw-bold underline opacity-50'>Performa <span className='d-none d-lg-inline'>Invoice</span></Link></div>
          <div className="col-6 flex-center"><Link to={'/bills'} className='h2 text-white text-center fw-bold text-underline'>Tax Invoice</Link></div>
        </div>
      </div>
      <div className="container">
        <Link className='btn btn-tertiary mt-4' to={'/bill/new'}>Create New Tax Invoice</Link>
        {bills && bills.map((item, index) => (
            <div key={index}
              className="d-flex flex-column flex-lg-row align-items-start justify-content-start align-items-lg-center justify-content-lg-between flex-wrap py-3 gap-3">
              <div className="flex-grow-1 w-100 w-lg-auto">
                <div onClick={()=>navigateToViewBill(item._id)} className={`cursor-pointer transition-hover shadow d-flex flex-wrap align-items-start justify-content-between p-3 rounded-2 fw-regular ${index%2===0 ? 'bg-white text-primary' : 'bg-primary-2 text-white'}`}>
                <div className='d-flex flex-column flex-lg-row'>
                    <div className="me-2">{`GTS-${item.taxYear}-${item.taxNumber}`}</div>
                    <div className="fw-bold">{item.billTo.name}</div>
                  </div>
                  <div>
                    {moment(item.createdAt).format('D MMM YY')}
                  </div>
                </div>
              </div>
              <div className='flex-center h-100'>
                <Tooltip title="Copy As PI" placement="top"><span className='shadow me-3 cursor-pointer transition-hover' onClick={()=>handleConvert(item._id)}>{copyToPI}</span></Tooltip>
                <Tooltip title="Duplicate Bill" placement="top"><span className='shadow me-3 cursor-pointer transition-hover' onClick={()=>handleDuplicate(item._id)}>{copyIcon}</span></Tooltip>
                <Tooltip title="Edit Bill" placement="top"><Link className='shadow me-3 cursor-pointer transition-hover' to={`/bill/${item._id}`}>{editIcon}</Link></Tooltip>
                <Tooltip title="Delete Bill" placement="top"><span className='shadow cursor-pointer transition-hover' onClick={()=>handleDelete(item._id)}>{deleteIcon}</span></Tooltip>
              </div>
            </div>
        ))}
      </div>
      <DuplicateHandler type={"bill"} id={selectedBillId} />
      <button className='btn btn-primary d-none' data-bs-toggle="modal" data-bs-target="#duplicateHandler" id='openDuplicateHandlerModal'>Duplicate Handler</button>
      <DeleteHandler type={"bill"} id={selectedBillId} />
      <button className='btn btn-primary d-none' data-bs-toggle="modal" data-bs-target="#deleteHandler" id='openDeleteHandlerModal'>Delete Handler</button>
      <ConvertHandler type={"bill"} id={selectedBillId} />
      <button className='btn btn-primary d-none' data-bs-toggle="modal" data-bs-target="#convertHandler" id='openConvertHandlerModal'>Convert Handler</button>
    </section>
  )
}

export default Bills